<template>
  <div
    class="page-block"
    :class="[
      backgroundClass,
      mobileSupport ? 'mobile-support' : '',
      mobileOrange ? 'mobile-orange' : '',
      orangeTitle ? 'orange-title' : '',
    ]"
  >
    <slot name="prehead"></slot>
    <h2 v-if="title" :class="'text-' + align" v-html="title"></h2>
    <h3 v-if="subtitle" :class="'text-' + align" v-html="subtitle"></h3>
    <h4 v-if="info" :class="'text-' + align" v-html="info"></h4>
    <div :class="{ 'page-body': pageBody, 'small-top': smallTop }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageBlock",
  props: {
    backgroundClass: {
      default: null,
    },
    title: {
      default: null,
    },
    subtitle: {
      default: null,
    },
    info: {
      default: null,
    },
    align: {
      default: "center",
    },
    pageBody: {
      default: false,
    },
    mobileSupport: {
      default: false,
    },
    mobileOrange: {
      default: false,
    },
    orangeTitle: {
      default: false,
    },
    smallTop: {
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.page-block {
  &.full {
    background: rgba(44, 44, 44, 0.7);
    padding: 40px 50px;
    border-radius: 12px;
    backdrop-filter: blur(5px);

    @media (max-width: 768px) {
      padding: 20px;
    }
  }
  &.gradient {
    background: linear-gradient(
      129.22deg,
      rgba(21, 21, 22, 0.9) 0%,
      rgba(50, 50, 51, 0.9) 97.03%
    );
  }
  h2 {
    font-weight: 700;
    font-size: 42px;
    margin-bottom: 12px;

    @media (max-width: 991px) {
      font-size: 32px;
    }

    @media (max-width: 768px) {
      font-size: 28px;
    }
  }
  h3 {
    font-size: 18px;
    color: #c4c4c4;
    font-weight: 500;
    @media (max-width: 991px) {
      line-height: 24px;
    }
  }
  h4 {
    font-size: 16px;
    color: #878787;
    font-weight: 400;
    @media (max-width: 991px) {
      font-size: 14px;
    }
    @media (min-width: 1200px) {
      max-width: 40vw;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .page-body {
    margin-top: 52px;
    &.small-top {
      margin-top: 32px;
    }
    @media (max-width: 767px) {
      margin-top: 42px;
      &.small-top {
        margin-top: 32px;
      }
    }
  }
  &.mobile-support {
    @media (max-width: 767px) {
      margin-top: 68px;
    }
  }
  &.mobile-orange {
    @media (max-width: 767px) {
      h2 {
        padding: 0 40px;
      }
      h3 {
        background-image: linear-gradient(to left, #ffa353, #d95e00);
        color: transparent;
        /* purgecss start ignore */
        background-clip: text;
        -webkit-background-clip: text;
        text-shadow: 0 0 transparent;
        /* purgecss end ignore */
        font-size: 20px;
        padding: 0 40px;
      }
    }
  }
  &.orange-title {
    h2 {
      background-image: linear-gradient(to left, #ffa353, #d95e00);
      color: transparent;

      /* purgecss start ignore */
      background-clip: text;
      -webkit-background-clip: text;
      text-shadow: 0 0 transparent;
      /* purgecss end ignore */
    }
  }
}
</style>
